// extracted by mini-css-extract-plugin
export const partnerHero = "partner-module__partnerHero--3xd6W";
export const innerWrapper = "inner-wrapper";
export const kicker = "kicker";
export const cta = "cta";
export const mediaContainer = "media-container";
export const partnerSubheadline = "partner-module__partnerSubheadline--32PxS";
export const appsOverview = "partner-module__appsOverview--22pXt";
export const appItem = "app-item";
export const appItemContent = "app-item-content";
export const appItemImg = "app-item-img";
export const appName = "app-name";
export const appDescription = "app-description";
export const linkComingSoon = "link-coming-soon";
export const benefitsContainer = "partner-module__benefitsContainer--306j_";
export const benefitsItem = "partner-module__benefitsItem--2wR6c";
export const iconWrapper = "icon-wrapper";
export const body = "body";
export const highlight = "highlight";
export const testimonialContainer = "partner-module__testimonialContainer--2y5sC";
export const testimonial = "partner-module__testimonial--3QQL9";
export const quoteWrapper = "quote-wrapper";
export const quote = "quote";
export const author = "author";
export const authorImg = "author-img";
export const authorDetails = "author-details";
export const authorName = "author-name";
export const authorPosition = "author-position";
export const contactContainer = "partner-module__contactContainer--1gy9p";
export const content = "content";
export const contentInner = "content-inner";
export const headline = "headline";
export const avatarContainer = "avatar-container";
export const avatarItem = "avatar-item";
export const avatarImg = "avatar-img";
export const avatarName = "avatar-name";
export const form = "form";
export const subheadline = "subheadline";
export const partnerParagraph = "partner-module__partnerParagraph--1wfSQ";