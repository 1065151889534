import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../container/main'
import SEO from '../components/seo'
import ContentHeroNew from '../components/content-hero-new'
import Container from '../components/container'
import Contact from '../components/contact'

import { testimonial, appsOverview, testimonialContainer, contactContainer, benefitsContainer, benefitsItem, partnerHero, partnerSubheadline, partnerParagraph } from './partner.module.scss'

const IndexPage = () => (
  <MainContainer>
    <SEO title="Partner" />
    <Container className={partnerHero}>
      <ContentHeroNew
        kicker="Become a Basaas Partner"
        headline="Provide your customers with the best foundation for continuous digital change"
        actionLinkText="Get in touch"
        actionLink="#get-in-touch"
        media={<StaticImage src="../assets/images/partner-hero.png" alt="Provide your customers with the best foundation for continuous digital change" placeholder="blurred" />}
      />
    </Container>
    <Container size="large" style={{ padding: '80px 0' }}>
      <p className={partnerParagraph}>
        The continuous digital change leads to digitization projects in most organizations. Our partners accompany organizations of all sizes in their digital advancement. As a Basaas partner, you can
        significantly improve your business, because Basaas offers your customers the perfect foundation for digitization projects.
      </p>
      <p className={partnerParagraph}>
        Basaas is the central solution for every employee to work across all apps. Such an overarching solution that unifies all apps enables the active involvement of employees in digitization
        projects. Basaas simplifies the daily work of all employees, and this leads to acceptance on a broad scale among employees.
      </p>
      <p className={partnerParagraph}>Basaas makes your customers' digitization projects more successful - Let's benefit from this together. </p>
    </Container>

    <Container size="large" style={{ padding: '80px 0' }} withBackground>
      <p className={partnerParagraph}>
        <h2 className={partnerSubheadline}>With Basaas you can connect all apps and employees into a unified enterprise solution</h2>
      </p>
      <div className={appsOverview}>
        <div className="app-item">
          <div className="app-item-content">
            <div className="app-item-img">
              <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="96" height="96" rx="18" fill="#3366FF" />
                <rect x="21" y="30.75" width="14.0162" height="14.0162" rx="3.52016" fill="white" />
                <rect x="21" y="50.8984" width="14.0162" height="14.0162" rx="3.52016" fill="white" />
                <rect x="41.1484" y="50.8984" width="14.0162" height="14.0162" rx="3.52016" fill="white" />
                <rect x="41.1484" y="30.8008" width="14.0162" height="14.0162" rx="3.52016" fill="white" />
                <rect x="61.2812" y="30.8008" width="14.0162" height="14.0162" rx="3.52016" fill="white" />
                <rect x="61.2964" y="50.8984" width="14.0162" height="14.0162" rx="3.52016" fill="white" />
              </svg>
            </div>
            <span className="app-name">Workplace</span>
            <span className="app-description">Enhance employee effciency with a unified and integrated workplace</span>
            <a className="linkBtn text" href="https://www.basaas.com/workplace" target="_blank" rel="noreferrer">
              Learn more
            </a>
          </div>
        </div>

        <div className="app-item">
          <div className="app-item-content">
            <div className="app-item-img">
              <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="96" height="96" rx="18" fill="#3366FF" />
                <rect x="16.7188" y="47.4033" width="14.0162" height="14.0162" rx="3.52016" transform="rotate(-45 16.7188 47.4033)" fill="white" />
                <rect x="30.9658" y="61.6504" width="14.0162" height="14.0162" rx="3.52016" transform="rotate(-45 30.9658 61.6504)" fill="white" />
                <rect x="45.2129" y="47.4033" width="14.0162" height="14.0162" rx="3.52016" transform="rotate(-45 45.2129 47.4033)" fill="white" />
                <rect x="59.4595" y="33.1567" width="14.0162" height="14.0162" rx="3.52016" transform="rotate(-45 59.4595 33.1567)" fill="white" />
              </svg>
            </div>
            <span className="app-name">Tasks</span>
            <span className="app-description">Increase team productivity with a cross-app task management</span>
            <a className="linkBtn text" href="https://www.basaas.com/tasks" target="_blank" rel="noreferrer">
              Learn more
            </a>
          </div>
        </div>

        <div className="app-item">
          <div className="app-item-content">
            <div className="app-item-img">
              <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="96" height="96" rx="18" fill="#3366FF" />
                <rect x="41.1484" y="32.8984" width="14.0162" height="14.0162" rx="3.52016" fill="white" />
                <rect x="41.1484" y="12.8008" width="14.0162" height="14.0162" rx="3.52016" fill="white" />
                <rect x="21.0005" y="33" width="14.0162" height="14.0162" rx="3.52016" fill="white" />
                <rect x="21" y="53.1484" width="14.0162" height="14.0162" rx="3.52016" fill="white" />
                <rect x="61.2812" y="33.0508" width="14.0162" height="14.0162" rx="3.52016" fill="white" />
                <rect x="61.2964" y="53.1484" width="14.0162" height="14.0162" rx="3.52016" fill="white" />
                <rect x="61.2964" y="73.125" width="14.0162" height="14.0162" rx="3.52016" fill="white" />
              </svg>
            </div>
            <span className="app-name">Flows</span>
            <span className="app-description">Automate and optimize company workflows across all apps</span>
            <span className="link-coming-soon">Coming soon</span>
          </div>
        </div>
      </div>
    </Container>

    <Container size="large" style={{ padding: '80px 0' }} withBackground>
      <p className={partnerParagraph}>
        <h2 className={partnerSubheadline}>Let&apos;s grow together</h2>
        Connect all apps and employees into one solution and help your customers to realize their full potential. Let’s unleash the full potential together. Basaas offers you a revenue share as well
        as customized partnership models for resellers, productivity experts and affiliates.
      </p>

      <div className={benefitsContainer}>
        <div className={benefitsItem}>
          <div className="inner-wrapper">
            <div className="icon-wrapper">
              <StaticImage src="../assets/feature-icons/all-time-revenue.png" alt="Benefit from an attractive lifetime revenue share" placeholder="blurred" />
            </div>
            <span className="body">
              Benefit from an <span className="highlight">attractive lifetime revenue share</span>
            </span>
          </div>
        </div>

        <div className={benefitsItem}>
          <div className="inner-wrapper">
            <div className="icon-wrapper">
              <StaticImage src="../assets/feature-icons/grow-your-business.png" alt="Grow your business due to successful digitization projects" placeholder="blurred" />
            </div>
            <span className="body">
              <span className="highlight">Grow your business</span> due to successful digitization projects
            </span>
          </div>
        </div>

        <div className={benefitsItem}>
          <div className="inner-wrapper">
            <div className="icon-wrapper">
              <StaticImage src="../assets/feature-icons/get-workload-insights.png" alt="Long-term customer loyalty through a unified digital hub" placeholder="blurred" />
            </div>
            <span className="body">
              <span className="highlight">Long-term customer loyalty</span> through a unified digital hub
            </span>
          </div>
        </div>

        <div className={benefitsItem}>
          <div className="inner-wrapper">
            <div className="icon-wrapper">
              <StaticImage src="../assets/feature-icons/manage-all-projects-in-one-solution.png" alt="Personal Go to Market support with tailored campaignes" placeholder="blurred" />
            </div>
            <span className="body">
              <span className="highlight">Personal Go to Market support</span> with tailored campaignes
            </span>
          </div>
        </div>

        <div className={benefitsItem}>
          <div className="inner-wrapper">
            <div className="icon-wrapper">
              <StaticImage src="../assets/feature-icons/organize-remote-working-teams.png" alt="Onboarding concept with free product and sales training" placeholder="blurred" />
            </div>
            <span className="body">
              <span className="highlight">Onboarding concept</span> with free product and sales training
            </span>
          </div>
        </div>

        <div className={benefitsItem}>
          <div className="inner-wrapper">
            <div className="icon-wrapper">
              <StaticImage src="../assets/feature-icons/technical-support.png" alt="Dedicated technical support</span> for individual customer requirements" placeholder="blurred" />
            </div>
            <span className="body">
              Dedicated <span className="highlight">technical support</span> for individual customer requirements
            </span>
          </div>
        </div>
      </div>
    </Container>

    <Container style={{ margin: '80px auto' }}>
      <p className={partnerParagraph}>
        <h2 className={partnerSubheadline}>What our partners say about us</h2>
      </p>
      <div className={testimonialContainer}>
        <div className={testimonial}>
          <div className="author">
            <div className="author-img">
              <StaticImage className="avatar-img" src="../assets/images/partner-avatar-kanzleivision.jpg" alt="Frank, CEO" placeholder="blurred" />
            </div>
            <div className="author-details">
              <span className="author-name">Christian Weiss</span>
              <span className="author-position">Managing Director</span>
              <span className="author-company-img">
                <a href="https://www.kanzleivision.de" target="_blank" rel="noreferrer">
                  <StaticImage src="../assets/images/partner-logo-kanzleivision.png" alt="Kanzleivision" placeholder="blurred" width={140} />
                </a>
              </span>
            </div>
          </div>
          <div className="quote-wrapper">
            <div className="quote">
              Together with Basaas, Kanzleivision supports more than 30 medium-sized tax and law firms in their digital journey. The first step is always the implementation of Basaas, because the so
              called “Kanzlei Dashboard“ is always the foundation for every digital transformation project.
            </div>
          </div>
        </div>

        <div className={testimonial}>
          <div className="author">
            <div className="author-img">
              <StaticImage className="avatar-img" src="../assets/images/partner-avatar-hirschtec.jpg" alt="Frank, CEO" placeholder="blurred" />
            </div>
            <div className="author-details">
              <span className="author-name">Felix Schröder</span>
              <span className="author-position">Team Leader Strategy & New Business</span>
              <span className="author-company-img" style={{ height: '20px' }}>
                <a href="https://hirschtec.eu" target="_blank" rel="noreferrer">
                  <StaticImage src="../assets/images/partner-logo-hirschtec.png" alt="HirschTec" placeholder="blurred" width={100} />
                </a>
              </span>
            </div>
          </div>
          <div className="quote-wrapper">
            <div className="quote">
              As a full-service agency for digital workplaces for large companies we connect people, organizations and technologies and turn the Digital Workplace into a place of cohesion, information
              and exchange. As a digital hub, Basaas is an essential part of our portfolio.
            </div>
          </div>
        </div>
      </div>
    </Container>

    <div className={`${contactContainer}`} id="get-in-touch">
      <div className="inner-wrapper">
        <div className="content">
          <div className="content-inner">
            {/* <span className="kicker">Request a demo</span> */}
            <h1 className="headline">Let&apos;s talk about our partnership</h1>
            <p className={partnerParagraph} style={{ margin: '24px 0' }}>
              We are actively expanding our international partner network. Therefore, I am looking forward to getting to know you.
            </p>
            <p className={partnerParagraph} style={{ margin: '24px 0' }}>
              As a first step, let&apos;s organize a personal online meeting to get to know each other. I will contact you shortly after the registration.
            </p>
            <div className="avatar-container">
              <div className="avatar-item">
                <StaticImage className="avatar-img" src="../assets/images/avatar-frank.jpg" alt="Frank, CEO" placeholder="blurred" />
                <span className="avatar-name">Frank, CEO</span>
              </div>
            </div>
          </div>
        </div>
        <div className="form">
          <Contact type="partner-registry" />
        </div>
      </div>
    </div>
  </MainContainer>
)

export default IndexPage

export const query = graphql`
  query {
    placeholderLandscape: file(relativePath: { eq: "placeholder-landscape.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 84)
      }
    }

    placeholderPortrait: file(relativePath: { eq: "placeholder-portrait.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 84)
      }
    }

    placeholderSquare: file(relativePath: { eq: "placeholder-square.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 200, quality: 84)
      }
    }
  }
`
